import getInstance from './instance';

export const createPoints = data => getInstance()
  .post('/api/process', data);

export async function upload(file) {
  const data = new FormData();
  data.append("file", file);
  const { data: { filename } } = await (getInstance().put('api/upload', data));
  return filename;
}