import BaseModel from "../base/BaseMode";

export default class PointUploadModel extends BaseModel {
  mapProps({ name, id }) {
    Object.assign(this, { name, id });
  }

  getObject() {
    const { name, id } = this;
    return { name, id }
  }
}