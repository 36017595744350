import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 2,
  }
});

const BaseForm = ({ classes, children, ...rest }) => {
  return (
    <form className={classes.root} {...rest}>
      {children}
    </form>
  );
};

export default withStyles(styles)(BaseForm);