import UploadIcon from '@material-ui/icons/CloudUpload'
import PointsIcon from '@material-ui/icons/PinDrop'
import UploadPoints from '../layouts/UploadPoints';
import Points from '../layouts/Points';

export default [
  {
    sidebarIcon: PointsIcon,
    sidebarText: "Points",
    component: Points,
    path:"/points"
  },
  {
    sidebarIcon: UploadIcon,
    sidebarText: "Upload Points",
    component: UploadPoints,
    path:"/upload-points"
  },
  {
    invisible: true,
    redirect: true,
    to:"/upload-points"
  },
]