import React, { Component } from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';

export default function LoginContainer(Wrapped) {
  class LoginWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        login: "",
        password: "",
        error: false,
      }
    }

    handleSubmit = e => {
      const { session } = this.props;
      e.preventDefault();
      if (session.isFetching) {
        return;
      }
      const { login, password } = this.state;
      if (!login || !password) {
        return;
      }
      session.loginUser({ username: login, password })
        .catch(() => this.setState({ password: "", error: true }));
    }

    handleChange = ({ target: { name, value }}) => {
      this.setState({ [name]: value, error: false });
    }
    
    render() {
      return (
        <Wrapped
          { ...this.props }
          { ...this.state }
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
        />
      );
    }
  }
  
  LoginWrapper = inject('session')(LoginWrapper)

  LoginWrapper.propTypes = {
    session: PropTypes.object,
  };
  return LoginWrapper;
}