import React from 'react';
import { Provider, observer } from 'mobx-react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CssBaseline from '@material-ui/core/CssBaseline';
import Notifier from '../layouts/Notifier';
import App from '../layouts/App';

const Root = observer(({ stores, theme }) => {
  return (
    <Provider {...stores}>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider 
          maxSnack={3}
          action={
            <IconButton key="close" aria-label="Close" color="inherit">
              <CloseIcon />
            </IconButton>
          }
          autoHideDuration={4000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}>
          <React.Fragment>
            <CssBaseline/>
            <Router basename="/panel">
              <App/>
            </Router>
            <Notifier/>
          </React.Fragment>
        </SnackbarProvider>
      </MuiThemeProvider>
    </Provider>
  );
});

export default Root;