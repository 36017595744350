import React, { PureComponent } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

class PointsListItem extends PureComponent {
  handleClickDelete = e => {
    const { item, onDelete } = this.props;
    onDelete(item);
  }
  render() {
    const { item } = this.props;
    return (
      <React.Fragment>
        <ListItem >
          <ListItemText primary={item.name}/>
          <ListItemSecondaryAction>
            <IconButton onClick={this.handleClickDelete}>
              <DeleteIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider component="li"/>
      </React.Fragment>
    );
  }
}

PointsListItem.propTypes = {

};

export default PointsListItem;