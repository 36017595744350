/* eslint-disable no-unused-vars */
import PointsUploadViewModel from "./PointsUploadViewModel";
import PointsUploadNetService from "./PointsUploadNetService";
/* eslint-enable no-unused-vars */

export default class PointsUploadContainer {
    /**
   * @param {PointsUploadViewModel} Model 
   * @param {PointsUploadNetService} netService 
   */
  constructor(Model, netService) {
    this.Model = Model;
    this.netService = netService;
  }
  
  async createPoints(data) {
    const item = new this.Model(data);
    await this.netService.createPoints(item);
  }
  
}