import tokenStorage from '../helpers/tokenStorage';
import { observable, decorate, action } from "mobx";
import * as api from '../api/auth';

class Session {  
  isLoggedIn = !!tokenStorage.getToken();

  loginUser(payload) {
    return api.loginUser(payload)
      .then(({ data: { token } }) => {
        tokenStorage.setToken(token);
        this.isLoggedIn = true;
      })
  }

  discardToken() {
    this.isLoggedIn = false;
    tokenStorage.discardToken();
  }
}

decorate(Session, {
  isLoggedIn: observable,
  loginUser: action,
  discardToken: action,
});

export default Session;