import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    paddingTop: 0,
  }
})

const BasePaper = ({ classes, children, ...rest }) => {
  return (
    <Paper classes={{ root: classes.root }} {...rest}>
      {children}
    </Paper>
  );
};

export default withStyles(styles)(BasePaper);