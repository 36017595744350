import axios from "axios";
// import store from '../store';
import toketStorage from "../helpers/tokenStorage";

const END_POINT = "/";

let _instance;
let lastToken;
let errorHandler;

export default function getInstance() {
  let token = toketStorage.getToken();
  if (!_instance || (token && token !== lastToken)) {
    _instance = createInstance(token);
  }
  return _instance;
}

function createInstance(token) {
  const options = {
    baseURL: END_POINT,
  };
  if (token) {
    options.headers = { AUTHORIZATION: `Token ${token}` };
    lastToken = token;
  }
  let instance = axios.create(options);
  if (errorHandler) {
    instance.interceptors.response.use(resp => resp, errorHandler);
  }
  return instance;
};

export function setErrorHandler(handler) {
  errorHandler = handler;
  createInstance(lastToken);
}

export function fakeInstance() {
  return {
    get: (query, { params }) => {
      let data = [];
      const { code } = params;
      switch (query) {
        case '/api/admin/get/countries':
          if (code === "U") {
            data = ['UA', 'UK', 'UG']
          }
          if (code === 'G') {
            data = ['GB', 'GA', 'GM']
          }
          break;
        case '/api/admin/get/cities':
          const { city } = params;
          if (code === "UA") {
            if (city.startsWith('K')) {
              data = ['Kyiv', 'Kharkiv']
            }
            if (city.startsWith('L')) {
              data = ['Lviv']
            }
            if (city.startsWith('O')) {
              data = ['Odessa']
            }
          }
          break;
        default:
          data = []
      }
      return new Promise(resolve => resolve({ data }));
    }
  }
}