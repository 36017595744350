import React from 'react';
import { withRouter } from 'react-router-dom';
import Dashboard from '../Dashboard'
import Login from '../Login'
import { inject, observer } from 'mobx-react';

const App = ({ session }) => {
  if (session.isLoggedIn) {
    return <Dashboard/>
  }
  return <Login/>
};

export default withRouter(inject('session')(observer(App)));