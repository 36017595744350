import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  }
})

const NoItems = ({
  classes,
  loading,
  loadingText="Loading...",
  noItemsText="No items"
}) => {
  return (
    <Typography
      variant="body1"
      align="center"
      className={classes.root}
    >
      { loading ? loadingText : noItemsText }
    </Typography>
  );
};

export default withStyles(styles)(NoItems);