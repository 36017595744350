export default function deleteDialog(
  openProp="openDelete",
  itemName="deleteItem"
) {
  const handleOpenDelete = function handleOpenDelete(item) {
    this.setState({
      [openProp]: true,
      [itemName]: item,
    });
  }
  
  const handleCloseDelete = function handleCloseDelete() {
    this.setState({
      [openProp]: false,
      [itemName]: null,
    });
  }

  return {
    handleOpenDelete,
    handleCloseDelete,
  }
}