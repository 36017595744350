export function fileHandler({ target: { files, value, name } }) {
  if (files.length === 0) {
    dropFile.call(this);
    return;
  }
  if (files.length === 1) {
    [files] = files;
  } else {
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files[i]);
    }
    files = filesArray;
  }
  this[name] = files;
}

export function dropInputError({ target: { name }}) {
  const { errors } = this.state;
  if (errors[name]) {
    this.setState({
      errors: {
        ...errors,
        [name]: false,
      }
    })
  }
}

function dropFile(name) {
  this.setState({
    [name]: "",
  });
  this[name] = null;
}
