import React, { PureComponent } from 'react';
import { inject } from 'mobx-react';
import DeleteDialog from '../../components/DeleteDialog';

import deleteDialog from '../../helpers/deleteDialog';
import { loadCities } from '../../helpers/codesLoader';

export default function PointsContainer(Wrapped) {
  class PointsWrapper extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        city: null,
        country: null,
        loading: false,
        openDelete: false,
        deleteItem: null,
        openDeleteCityPoints: false,
        cityItem: null,
      }
      const { 
        handleOpenDelete: handleOpenDeletePoint,
        handleCloseDelete: handleCloseDeletePoint,
      } = deleteDialog();
      this.handleOpenDeletePoint = handleOpenDeletePoint.bind(this);
      this.handleCloseDeletePoint = handleCloseDeletePoint.bind(this);

      const { 
        handleOpenDelete: handleOpenDeleteCityPoints,
        handleCloseDelete: handleCloseDeleteCityPoints,
      } = deleteDialog('openDeleteCityPoints', 'cityItem');
      this.handleOpenDeleteCityPoints = handleOpenDeleteCityPoints.bind(this);
      this.handleCloseDeleteCityPoints = handleCloseDeleteCityPoints.bind(this);
    }

    handleCodesChange = (value, { name }) => {
      if (this.state[name] && value && value.value === this.state[name].value) {
        return;
      }
      const newState = {
        [name]: value
      };
      if (name === 'country') {
        newState.city = null;
      } else {
        this.loadPoints({ [name]: value });
      }
      this.setState(newState);
    }

    async loadPoints(props={}) {
      const city = props.city || this.state.city;
      const code = props.country || this.state.country;

      if (!city || !code) {
        return;
      }

      this.setState({ loading: true });
      try {
        await this.props.points.loadList({
          city: city.value,
          code: code.value,
        });
      }
      finally {
        this.setState({ loading: false });
      }
    }
    
    loadCities = city => {
      return loadCities({
        code: this.state.country.value,
        city
      })
    }

    handleDeletePoint = async () => {
      const {
        city: { value: city },
        country: { value: code },
        deleteItem,
      } = this.state;
      this.setState({
        loading: true
      });
      try {
        await this.props.points.delete(deleteItem, { city, code});
      } finally {
        this.setState({ loading: false });
      this.handleCloseDeletePoint   ();
      }
    }

    handleDeleteCityPointsClick = () => {
      const {
        city: { value: city },
        country: { value: country },
      } = this.state;
      this.handleOpenDeleteCityPoints({ city, country });
    }

    handleDeleteCityPoints = async () => {
      const {
        city: { value: city },
        country: { value: country },
      } = this.state;
      this.setState({ loading: true })
      try {
        await this.props.points.deleteCityPoints(country, city);
        this.handleCloseDeleteCityPoints();
      } finally {
        this.setState({ loading: false });
      }
    }

    getDeleteCityPointsDescription() {
      const { cityItem } = this.state;
      if (cityItem) {
        return `points country: ${cityItem.country} city: ${cityItem.city}`;
      }
      return '';
    }

    render() {
      const { deleteItem } = this.state;
      return (
        <React.Fragment>
          <Wrapped
            {...this.state}
            {...this.props}
            onChange={this.handleChange}
            onDelete={this.handleOpenDeletePoint}
            onCodesChange={this.handleCodesChange}
            loadCities={this.loadCities}
            onClickDeleteCityPoints={this.handleDeleteCityPointsClick}
          />
          <DeleteDialog
            open={this.state.openDelete}
            onClose={this.handleCloseDeletePoint}
            description={deleteItem ? deleteItem.name : ""}
            onDelete={this.handleDeletePoint}
          />
          
          <DeleteDialog
            open={this.state.openDeleteCityPoints}
            onClose={this.handleCloseDeleteCityPoints}
            description={this.getDeleteCityPointsDescription()}
            onDelete={this.handleDeleteCityPoints}
          />
        </React.Fragment>
      );
    }
  }
  
  PointsWrapper.propTypes = {
  
  };
  
  return inject('points')(PointsWrapper);
}