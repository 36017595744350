import Notifier from './Notifier';
import Session from './Session';
import PointsModel from './Points/PointsModel'
import PointsModelContainer from './Points/PointsModelContainer'
import PointsNetService from './Points/PointsNetService'

import * as pointsApi from '../api/points';

import PointsUploadContainer from './Points/PointsUploadContainer';
import PointsUploadNetService from './Points/PointsUploadNetService';
import PointsUploadViewModel from './Points/PointsUploadViewModel';
import * as uploadPointsApi from '../api/upload-points';

const notifier = new Notifier();
const session = new Session();

const pointsNetService = new PointsNetService(pointsApi);
const points = new PointsModelContainer(PointsModel, pointsNetService);

const pointsUploadNetService = new PointsUploadNetService(uploadPointsApi);
export const pointsUpload = new PointsUploadContainer(PointsUploadViewModel, pointsUploadNetService);

export default {
  notifier,
  session,
  points
}