import WithFilesNetService from '../base/WithFilesNetService';

/* eslint-disable no-unused-vars */
import PointsUploadViewModel from './PointsUploadViewModel';
/* eslint-enable no-unused-vars */

export default class PointsUploadNetService extends WithFilesNetService {
  /**
   * 
   * @param {PointsUploadViewModel} item 
   */
  async createPoints(item) {
    this.typeCheck(item);
    const filenames = await this.uploadFiles(item);
    item.setFilenames(filenames);
    await this.api.createPoints(item.getObject())
  }
}