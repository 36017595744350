import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import BasePaper from '../../components/BasePaper';
import CustomLinearProgress from '../../components/CustomLinearProgress';
import CustomToolbar from '../../components/CustomToolbar';
import PointsContainer from './container';
import PointsListItem from './listItem';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import SelectAutocomplete from '../../components/SelectAutocomplete';
import { loadCountries } from "../../helpers/codesLoader";
import NoItems from '../../components/NoItems';

const submitMock = (e) => { e.preventDefault() };

const styles = theme => ({
  inputStyle: {
    maxWidth: 170,
    '& + &': {
      marginLeft: theme.spacing.unit * 2
    }
  },
  searchForm: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 3,
  },
  deleteButton: {
    marginLeft: theme.spacing.unit * 3,
  }
})

const Points = observer(({
  loading,
  country,
  city,
  points,
  classes,
  onDelete,
  onCodesChange,
  loadCities,
  onClickDeleteCityPoints
}) => {
  return (
    <BasePaper>
      {loading && <CustomLinearProgress/>}
      <CustomToolbar>
        Points
      </CustomToolbar>
      <form className={classes.searchForm} onSubmit={submitMock}>
        <SelectAutocomplete
          name="country"
          onChange={onCodesChange}
          cacheOptions
          isAsync
          textFieldProps={{
            label: 'Country',
            InputLabelProps: {
              shrink: true,
            },
          }}
          value={country}
          loadOptions={loadCountries}
          className={classes.inputStyle}
          isClearable
        />
        <SelectAutocomplete
          name="city"
          onChange={onCodesChange}
          isAsync
          textFieldProps={{
            label: 'City',
            InputLabelProps: {
              shrink: true,
            },
          }}
          value={city}
          loadOptions={loadCities}
          className={classes.inputStyle}
          isClearable
        />
        <Button
          disabled={!city || !country}
          color="secondary"
          className={classes.deleteButton}
          onClick={onClickDeleteCityPoints}
        >
          Delete city points
        </Button>
      </form>
      { (points.list.length === 0) ? (
        <NoItems loading={loading} />
      ) : (
        <List>
          {points.list.map(item => (
            <PointsListItem
              key={item.id}
              item={item}
              onDelete={onDelete}
            />
          ))}
        </List>
      )}
    </BasePaper>
  );
});

export default PointsContainer(
  withStyles(styles)(Points)
);