import getInstance from './instance';
// import { fakeInstance } from './instance';


export const getCountries = async code => {
  const { data } = await getInstance().get('/api/admin/get/countries', {
    params: { code }
  });
  return data.map(item => ({ label: item, value: item }));
}

export const getCities = async params => {
  const { data } = await getInstance().get('/api/admin/get/cities', {
    params,
  });
  return data.map(item => ({ label: item, value: item }));
}