import debounce from 'debounce-async';
import * as api from '../api/codes';


export const loadCountries = debounce(
  code => api.getCountries(code), 600
);

export const loadCities = debounce(
  params => api.getCities(params), 600
); 