import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const styles = theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: theme.zIndex.modal + 10,
  }
})

const CustomLinearProgress = ({ classes, ...rest }) => {
  return (
    <LinearProgress
      classes={{
        root: classes.root,
        ...classes,
      }}
      {...rest}
    />
  );
};

export default withStyles(styles)(CustomLinearProgress);