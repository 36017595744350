export default class BaseNetService {
  constructor(api) {
    this.api = api;
  }
  
  async getList(params) {
    const { data } = await this.api.getList(params);
    return data;
  }

  async getItem(params) {
    const { data } = await this.api.getList(params);
    return data;
  }

  async create(item, options) {
    const { data } = await this.api.create(item.getObject(), options);
    return data;
  }

  async update(item, options) {
    const { data } = await this.api.upldate(item.getObject(), options);
    return data;
  }

  delete(item, options) {
    return this.api.remove(item.getObject(), options);
  }
}