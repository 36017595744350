import BaseModelContainer from '../base/BaseModelsContainer'

export default class PointsModelContainer extends BaseModelContainer {

  async loadList(options) {
    const data = await this.netService.getList(options);
    const list = data.points
      .map(({ id, locale }) => new this.Model({
        id, name: locale.ru.name
      }));
    this.list = list;
  }

  async deleteCityPoints(country, city) {
    await this.netService.deleteCityPoints({ code: country, city });
    this.list = [];
  }
}