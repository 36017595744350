import ModelWithFiles from "../base/ModelWithFiles";

export default class PointsUploadViewModel extends ModelWithFiles {
  
  mapProps({ filenames, city, country, type }) {
    Object.assign(this, { filenames, city, country, type });
  }

  getObject() {
    const { filenames, city, country, type } = this; 
    return { filenames, city, country, type };
  }

  getFilesToUpload() {
    return { filenames: this.filenames }
  }
}