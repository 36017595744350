import React, { PureComponent } from 'react';
import { fileHandler, dropInputError } from '../../helpers/handlers';
import { pointsUpload } from "../../store";
import { withSnackbar } from 'notistack';
import { fileValidator } from '../../helpers/fileValidator';
import { types } from '../../config';
import { loadCities } from '../../helpers/codesLoader';

const typesArray = Object.entries(types)
  .map(([key, value]) => ({ key, value }));

const defaultState = {
  filenames: "",
  country: null,
  city: null,
  errors: {
    filenames: false,
  },
  loading: false,
  type: typesArray[0].key,
}

export default function UploadPointsContainer(Wrapped) {
  class UploadPointsWrapper extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        ...defaultState
      }
    }

    handleChange = e => {
      const { name, value } = e.target;
      if (name === 'filenames') {
        fileHandler.call(this, e);
        dropInputError.call(this, e);
        return;
      }
      this.setState({
        [name]: value
      });
      dropInputError.call(this, e);
    }
    
    handleCodesChange = (value, { name }) => {
      this.setState({
        [name]: value
      });
    }

    loadCities = city => {
      return loadCities({
        code: this.state.country.value,
        city
      })
    }

    handleSubmit = async e => {
      e.preventDefault();
      if (!this.isValid()) {
        return;
      }
      const { country, city, type } = this.state;
      let { filenames } = this;
      if (!Array.isArray(filenames)) {
        filenames = [filenames];
      }
      this.setState({
        loading: true,
      });
      try {
        await pointsUpload.createPoints({
          filenames,
          country: country.value,
          city: city.value,
          type
        });
        this.props.enqueueSnackbar('Successfully saved', {
          variant: "success",
        });
        this.setState({
          ...defaultState,
        });
        this.filenames = null;
      }
      catch(error) {
        const { response } = error;
        if (response && response.status === 400) {
          this.props.enqueueSnackbar('Saving failed. Check your data.', {
            variant: "error",
          });
        }
      }
      finally {
        this.setState({
          loading: false
        });
      }
    }

    isValid() {
      let errors = { ...this.state.errors };
      errors = fileValidator.call(this, 'filenames', errors);
      this.setState({ errors });
      return !Object.values(errors).some(value => !!value);
    }
    
    render() {
      return (
        <Wrapped
          {...this.props}
          {...this.state}
          typesArray={typesArray}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onCodesChange={this.handleCodesChange}
          loadCities={this.loadCities}
        />
      );
    }
  }

  return withSnackbar(UploadPointsWrapper);
}

