import getInstance from './instance';

export const getList = options => getInstance()
    .post('/api/city', options);

export const remove = ({ id }, options) => getInstance()
  .post('/api/admin/remove/marker', {
    id,
    ...options,
  });

export const deleteCityPoints = options => getInstance()
  .post('/api/admin/remove/city', options);