import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import container from './container';
import CustomLinearProgress from '../../components/CustomLinearProgress';
import CustomToolbar from '../../components/CustomToolbar';
import FileInput from '../../components/FileInput';
import BasePaper from '../../components/BasePaper';
import BaseForm from '../../components/BaseForm';
import SelectAutocomplete from '../../components/SelectAutocomplete';
import { loadCountries } from '../../helpers/codesLoader';

const styles = theme => ({
  inputs: {
    marginTop: theme.spacing.unit * 2,
  },
  submitButton: {
    marginLeft: theme.spacing.unit * 2,
  }
})

const index = ({
  onSubmit,
  loading,
  filenames,
  country,
  city,
  onChange,
  errors,
  classes,
  type,
  typesArray,
  onCodesChange,
  loadCities,
}) => {
  return (
    <BasePaper>
      {loading && <CustomLinearProgress/>}
      <CustomToolbar>
        Upload Points
      </CustomToolbar>
      <BaseForm onSubmit={onSubmit}>
        {/* <TextField
          margin="normal"
          name="country"
          onChange={onChange}
          value={country}
          label="Country"
          fullWidth
          required
        />
        <TextField
          name="city"
          onChange={onChange}
          value={city}
          label="City"
          fullWidth
          required
          margin="normal"
        /> */}
        <SelectAutocomplete
          name="country"
          onChange={onCodesChange}
          cacheOptions
          isAsync
          textFieldProps={{
            label: 'Country',
            InputLabelProps: {
              shrink: true,
            },
            margin:"normal",
            fullWidth: true,
            required: true,
          }}
          value={country}
          loadOptions={loadCountries}
          className={classes.inputStyle}
        />
        <SelectAutocomplete
          name="city"
          onChange={onCodesChange}
          cacheOptions
          isAsync
          textFieldProps={{
            label: 'City',
            InputLabelProps: {
              shrink: true,
            },
            margin:"normal",
            fullWidth: true,
            required: true,
          }}
          value={city}
          loadOptions={loadCities}
          className={classes.inputStyle}
        />
        <TextField
          name="type"
          onChange={onChange}
          value={type}
          label="Type"
          fullWidth
          required
          select
          margin="normal"
        >
          { typesArray.map(({ key, value }) => (
            <MenuItem key={key} value={key}>
              { value }
            </MenuItem>
          ))}
        </TextField>
        <div className={classes.inputs}>
          <FileInput
            id="excel-file-input"
            name="filenames"
            value={filenames}
            onChange={onChange}
            error={errors.filenames}
            inputProps={{
              multiple: true,
              accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }}
          />
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            className={classes.submitButton}
          >
            Save
          </Button>
        </div>
      </BaseForm>
    </BasePaper>
  );
};

export default container(withStyles(styles)(index));